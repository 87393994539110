import axios from 'axios'
import configuration from '@/configuration'
import toastService from './toast.service'
import authService from './auth.service'

export const rawInstance = axios.create({
  baseURL: configuration.API_URL
})
export const api = axios.create({
  baseURL: configuration.API_URL
})
// instance.defaults.params.auth = localStorage.token

// instance.interceptors.response.use(response => response, function (error) {
//   if (error.response && error.response.status === 400) {
//     if (error.response.data.error === 'token is expired') {
//       expiredSessionCallbacks.forEach(callback => callback())
//     }
//   } else if (error.message && error.message.toLowerCase().indexOf('network') >= 0) {
//     error.isNetworkError = true
//   }
//   return Promise.reject(error)
// })
api.interceptors.response.use(result => result.data ? result.data : {}, error => {
  const errorData = error.response && error.response.data
  const errorToShow = (errorData && (errorData.message || errorData.error)) || error
  if (error.response?.status === 401 && error.response?.data?.code === 'invalid_session_error' && !error.config.headers.retry) {
    return authService.tryGetCurrentUserAndSetToken(true).then((user) => {
      if (user) {
        error.config.headers.authorization = getAuthHeader()
        error.config.headers.retry = true
        return rawInstance.request(error.config)
      }
      toastService.error(errorToShow)
      return null
    })
  }
  if (error.response?.status === 401) {
    if (error.response?.data?.code === 'invalid_code') {
      toastService.error('Código de inicio de sesión no es válido.')
    } else toastService.error('Esta cuenta no tiene los permisos suficientes para realizar la acción.')
  } else toastService.error(errorToShow)
  return null
})

const getAuthHeader = () => `Bearer ${localStorage.token}`

export const setToken = (token = null) => {
  if (token) {
    localStorage.token = token
  }
  const authorization = getAuthHeader()
  rawInstance.defaults.headers.authorization = authorization
  api.defaults.headers.authorization = authorization
}

export const clearToken = () => {
  localStorage.removeItem('token')
  delete rawInstance.defaults.headers.authorization
  delete api.defaults.headers.authorization
}

setToken()

export default api
