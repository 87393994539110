export const groupBy = (array, prop) => {
  if (!array) return {}
  return array.reduce((memo, item) => {
    if (!memo[item[prop]]) memo[item[prop]] = []
    memo[item[prop]].push(item)
    return memo
  }, {})
}

export const uniqueProp = (array, prop) => {
  return array && [...new Set(array.map(item => item[prop]))]
}

export const distinct = (array, unique, map) => {
  const uniques = {}
  array.forEach(item => {
    const id = unique(item)
    if (!uniques[id]) {
      uniques[id] = map(item)
    }
  })
  return Object.values(uniques)
}

// TODO: Deprecated? Parece que no funciona con mover de la posición cero a la uno.
export const moveArray = (array, oldIndex, newIndex) => {
  var tmp = array[oldIndex]
  array.splice(oldIndex, 1)
  if (oldIndex < newIndex) { array.splice(newIndex - 1, 0, tmp) } else {
    array.splice(newIndex, 0, tmp)
  }
  return array
}

export const moveArrayItem = (array, oldIndex, newIndex) => {
  if (newIndex >= array.length) {
    let k = newIndex - array.length + 1
    while (k--) {
      array.push(undefined)
    }
  }
  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0])
  return array
}

export const sortArray = (array, propName, ascending = true) => {
  const sorted = [...array]
  sorted.sort((a, b) => {
    if (a[propName] < b[propName]) {
      return ascending ? -1 : 1
    }
    if (a[propName] > b[propName]) {
      return ascending ? 1 : -1
    }
    return 0
  })
  return sorted
}
