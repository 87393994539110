<template lang="pug">
.numeric-input
  a.minus(@click='minus')
  label.numeric-input-label {{ internalValue }}
  a.plus(@click='plus')
</template>

<script>
export default {
  props: {
    value: { type: Number }
  },
  data () {
    return {
      internalValue: this.value
    }
  },
  watch: {
    value () {
      this.internalValue = this.value
    }
  },
  methods: {
    minus () {
      this.internalValue--
      this.$emit('input', this.internalValue)
    },
    plus () {
      this.internalValue++
      this.$emit('input', this.internalValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.numeric-input {
  display: flex;
  background: #fff;
  // max-width: 100px;
  min-width: 100px;
  border-radius: 60px;
  height: 40px;
  justify-content: space-between;
  align-items: center;
}

.minus,
.plus {
  width: 30px;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
}

.minus {
  background-image: url('../assets/numeric-input-minus.svg');
}

.plus {
  background-image: url('../assets/numeric-input-plus.svg');
}

.numeric-input-label {
  color: black;
  font-size: 16px;
}
</style>
