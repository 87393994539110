export const playerContolHomeRoute = {
  path: '',
  name: 'playerControlHome',
  meta: { requiresAuth: true },
  component: () => import(/* webpackChunkName: "player-control-home" */ '@/views/player-control/views/Home.vue')
}

export const notificationsRoute = {
  path: 'notifications',
  name: 'playerControlNotifications',
  meta: { requiresAuth: true },
  component: () => import(/* webpackChunkName: "player-control-notifications" */ '@/views/player-control/views/Notifications.vue')
}

export const connectRoute = {
  path: 'connect',
  name: 'playerControlConnect',
  meta: { requiresAuth: true, externalButtonEnabled: true, allowPlayerFeats: true },
  component: () => import(/* webpackChunkName: "connect-dashboard" */ '@/views/connect/Dashboard.vue')
}

export const listsRoute = {
  path: 'lists',
  name: 'playerControlLists',
  meta: { requiresAuth: true },
  component: () => import(/* webpackChunkName: "player-control-lists" */ '@/views/player-control/views/Lists.vue')
}

export const transactionsRoute = {
  path: 'transactions',
  name: 'playerControlTransactions',
  meta: { requiresAuth: true },
  component: () => import(/* webpackChunkName: "player-control-transactions" */ '@/views/player-control/views/Transactions.vue')
}

export const resultsRoute = {
  path: 'results',
  name: 'searchResults',
  meta: { requiresAuth: true },
  component: () => import(/* webpackChunkName: "player-control-search-results" */ '@/views/player-control/views/SearchResults.vue')
}

export const listDetailsRoute = {
  path: 'lists/:id',
  name: 'playerControlListDetails',
  meta: { requiresAuth: true },
  component: () => import(/* webpackChunkName: "player-control-list-details" */ '@/views/player-control/views/ListDetails.vue')
}

export const playerControlRoute = {
  path: '/',
  name: 'playerControl',
  component: () => import(/* webpackChunkName: "playerControl" */ '@/views/player-control/Index.vue'),
  children: [
    playerContolHomeRoute,
    resultsRoute,
    connectRoute,
    notificationsRoute,
    listsRoute,
    transactionsRoute,
    listDetailsRoute
  ]
}
