import configurationsService from '@/services/configurations.service'

const state = () => ({
  flags: {},
  loaded: false
})

const mutations = {
  setFlags: (state, flags) => {
    state.flags = flags
    state.loaded = true
  }
}

const actions = {
  load: async ({ commit }) => {
    const result = await configurationsService.getFeatureFlags()
    if (result) {
      await commit('setFlags', result)
    }
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
