import ordersService from '@/services/orders.service'

const state = () => ({
  products: [],
  isLoading: false,
  currentOrder: {},
  total: 0,
  freeTrialApplied: false,
  allowedForFreeTrial: false
})

const mutations = {
  setCurrentOrder: (state, order) => {
    state.currentOrder = order.order
    state.total = order.total
    state.freeTrialApplied = order.freeTrialApplied
    state.allowedForFreeTrial = order.allowedForFreeTrial
  },
  setIsLoading: (state, isLoading) => {
    state.isLoading = isLoading
  },
  setHaveNew: (state, haveNew) => {
    state.haveNew = haveNew
  }
}

const actions = {
  loadOrder: async ({ commit }, { planProduct, createIfNotExist, includeProductInfo } = {}) => {
    await commit('setIsLoading', true)
    const productPlanId = planProduct ? planProduct.id : undefined
    let orderResult = await ordersService.getCurrent({ productPlanId, includeProductInfo })
    if (orderResult && createIfNotExist) {
      if (!orderResult.id) {
        orderResult = await ordersService.post({ currency: 'cop', orderDetails: [{ productId: planProduct.id, quantity: 1 }] })
      }
    }
    if (orderResult && orderResult.id) {
      await commit('setCurrentOrder', orderResult)
    } else {
      // error
    }
    // await commit('setNotifications', result)
    // await commit('setHaveNew', haveNew)
    await commit('setIsLoading', false)
  },
  addProduct: async ({ commit, state }, detail) => {
    await commit('setIsLoading', true)
    const orderResult = await ordersService.createDetail(state.currentOrder.id, detail)
    if (orderResult && orderResult.id) {
      await commit('setCurrentOrder', orderResult)
    }
    await commit('setIsLoading', false)
  },
  updateProduct: async ({ commit, state }, detail) => {
    await commit('setIsLoading', true)
    const orderResult = await ordersService.updateDetail(state.currentOrder.id, detail)
    if (orderResult && orderResult.id) {
      await commit('setCurrentOrder', orderResult)
    }
    await commit('setIsLoading', false)
  }
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
